import React, { useState } from "react";
import { css } from "@emotion/react";
import { Link } from "gatsby";
import { AnimatePresence, motion } from "framer-motion";
import Overlay from "../overlay";

const Header = () => {
  const [open, setOpen] = useState(false);

  return (
    <nav
      css={css`
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.8) 0%,
          rgba(0, 0, 0, 0.2) 100%
        );
        color: white;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        box-sizing: border-box;
        height: 47px;
        z-index: 10000000;
        padding: 0.5rem 1rem;

        .content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-family: "Brandon Grotesque", sans-serif;
          height: 100%;

          .logo,
          .mobile-logo {
            font-size: 26px;
            font-weight: bold;
            color: white;
            text-decoration: none;
          }

          .logo {
            grid-area: Logo;
            place-self: center;
            text-align: center;
            display: none;
          }

          .nav-items {
            display: none;
            margin: 0;
            padding: 0;

            .nav-item {
              list-style: none;
              font-size: 14px;
              a {
                font-weight: bold;
                color: white;
                text-decoration: none;
              }
            }
          }

          .overlay.open {
            display: block;
            opacity: 1;
          }

          .mobile-nav-items {
            margin: 0;
            padding: 0;
          }

          @media screen and (min-width: 1024px) {
            display: grid;
            grid-template-areas: "navItemsLeft Logo navItemsRight";
            grid-template-columns: 1fr 0.1fr 1fr;
            place-content: center;

            .nav-items {
              display: grid;
              grid-template-columns: repeat(auto-fill, minmax(50px, 250px));
              place-content: center;
              text-align: center;
            }

            .item {
              display: none;
            }

            .hamburger {
              display: none;
            }

            .mobile-logo {
              display: none;
            }

            .logo {
              display: block;
            }
          }
        }

        @media screen and (min-width: 768px) {
          padding: 0.5rem calc((100vw - 750px) / 2);
        }
        @media screen and (min-width: 1024px) {
          padding: 1rem calc((100vw - 960px) / 2);
        }
        @media screen and (min-width: 1366px) {
          padding: 1rem calc((100vw - 1280px) / 2);
        }
        @media screen and (min-width: 1440px) {
          padding: 1.5rem calc((100vw - 1300px) / 2);
        }
      `}
    >
      <div className="content">
        <ul className="nav-items" style={{ gridArea: "navItemsLeft" }}>
          <li className="nav-item">
            <Link to={"/menu"}>MENU</Link>
          </li>
          <li className="nav-item">
            <Link to={"/market"}>THE MARKET</Link>
          </li>
          {/*<li className="nav-item">*/}
          {/*  <Link to={"/delivery"}>DELIVERY</Link>*/}
          {/*</li>*/}
        </ul>

        <Link className="logo" to={"/"}>
          NOMAD
        </Link>

        <Link className="mobile-logo" to={"/"}>
          NOMAD
        </Link>

        <ul className="nav-items" style={{ gridArea: "navItemsRight" }}>
          <li className="nav-item">
            <Link to={"/contact"}>CONTACT</Link>
          </li>
        </ul>

        <div
          className="hamburger"
          onClick={() => setOpen(!open)}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              setOpen(!open);
            }
          }}
          role="button"
          tabIndex={0}
        >
          <ion-icon name="menu-outline" size={"large"} />
        </div>

        <AnimatePresence>
          {open && (
            <Overlay send={setOpen} sendCommand={false}>
              <motion.div
                initial={{ x: 1000 }}
                animate={{
                  x: 0,
                  transition: { delay: 0.1, ease: "easeIn", duration: 0.3 },
                }}
                exit={{ x: 1000, transition: { delay: 0.1 } }}
                className={`sidebar`}
                onClick={(e) => e.stopPropagation()}
              >
                <div
                  css={css`
                    padding: 1rem;
                    display: flex;
                    flex: 1 0 100%;
                    flex-direction: row;
                    justify-content: flex-end;
                  `}
                >
                  <span style={{ cursor: "pointer" }}>
                    <ion-icon
                      name="close-outline"
                      size={"large"}
                      css={css`
                        color: black;
                      `}
                      onClick={() => setOpen(false)}
                    />
                  </span>
                </div>
                <ul className={"mobile-nav-items"}>
                  {/*<li className="item">*/}
                  {/*  <Link to={"/welcome"}>Welcome</Link>*/}
                  {/*</li>*/}
                  <li className="item">
                    <Link to={"/menu"}>Menu</Link>
                  </li>
                  <li className="item">
                    <Link to={"/market"}>The Market</Link>
                  </li>
                  {/*<li className="item">*/}
                  {/*  <Link to={"/delivery"}>Delivery</Link>*/}
                  {/*</li>*/}
                  <li className="item">
                    <Link to={"/contact"}>Contact</Link>
                  </li>
                </ul>
              </motion.div>
            </Overlay>
          )}
        </AnimatePresence>
      </div>
    </nav>
  );
};

export default Header;
