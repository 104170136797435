import React from "react";
import { useStaticQuery, graphql } from "gatsby";

const Image = () => {
  const data = useStaticQuery(graphql`
    query {
      indexBg: file(relativePath: { eq: "indexBg1.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      delivery: file(relativePath: { eq: "delivery.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      contact: file(relativePath: { eq: "markets2.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pattern1: file(relativePath: { eq: "patterns.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pattern2: file(relativePath: { eq: "patterns1.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      menu: file(relativePath: { eq: "menu.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      market: file(relativePath: { eq: "markets.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      market2: file(relativePath: { eq: "markets2.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return {
    indexBg: data.indexBg.childImageSharp.fluid,
    delivery: data.delivery.childImageSharp.fluid,
    pattern1: data.pattern1.childImageSharp.fluid,
    pattern2: data.pattern2.childImageSharp.fluid,
    menu: data.menu.childImageSharp.fluid,
    market: data.market.childImageSharp.fluid,
    market2: data.market2.childImageSharp.fluid,
    contact: data.contact.childImageSharp.fluid,
  };
};

export default Image;
