import React, { useEffect } from "react";
import * as ReactDOM from "react-dom";
import { motion } from "framer-motion";

const Overlay = ({ children, send, sendCommand }) => {
  const modalRoot = document.getElementById("overlay-root");

  useEffect(() => {
    const top = document.scrollingElement.scrollTop;
    document.body.style.position = "fixed";
    document.body.style.top = `-${top}px`;
    document.body.style.width = "100%";
    document.body.style.overflowY = "hidden";
    document.body.style.height = "auto";
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.position = "";
      document.body.style.top = "";
      document.body.style.width = "";
      document.body.style.overflowY = "";
      document.body.style.paddingRight = "";
      document.body.style.height = "";
      document.documentElement.style.scrollBehavior = "auto";
      window.scrollTo({
        left: 0,
        top: parseInt(scrollY || "0") * -1,
        behavior: "auto",
      });
    };
  }, []);

  return ReactDOM.createPortal(
    <motion.div
      key="modal"
      className="overlay"
      onClick={() => send(sendCommand)}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.2 }}
      exit={{ opacity: 0 }}
    >
      {children}
    </motion.div>,
    modalRoot
  );
};

export default Overlay;
